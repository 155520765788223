$grey: #4a4a4a;
$lightYellow:#fff300;
$lightBlack:#333333;
$darkYellow:#ded300;
$lighterGrey:#f2f2f2;
$darkGrey:#3f3b3a;
$lightGrey: #6d6d6d;
$bodyFontColor: $grey;
$bodyFontSize: 1.6rem;
$buttonBgColor:$lightYellow;
$buttonHoverColor:$darkYellow;
$fontFamily: PingFangSC-Regular,
"Microsoft YaHei";
$backgroundColor:$lighterGrey;
$selectedFontColor:$lightYellow;
$secectedFontBgColor:$darkGrey;
$hWeight:500;
$strongWeight:600;
$strongColor:$lightGrey;
$transition:.3s all ease-in-out;
$deepShitYellow:#f5a623;
html {
  font-size: 62.5%;
}

body {
  color: $lightBlack;
  font-size: $bodyFontSize;
  font-family: $fontFamily;
  position: relative; // background: $backgroundColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 80px;
}

*::-moz-selection {
  background-color: $secectedFontBgColor;
  color: $selectedFontColor;
}

*::selection {
  background-color: $secectedFontBgColor;
  color: $selectedFontColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $hWeight;
  color: $bodyFontColor;
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.4rem;
}

strong {
  // font-weight: $strongWeight;
  //其实bootstrap默认的700也可以 但是600就不行
  font-weight: bold;
}

a:link,
a:visited,
a:hover,
a:active {
  color: #9b9b9b;
  text-decoration: none;
  transition: $transition;
}

a:hover {
  color: #4cc1fa;
}

p {
  margin: 0;
}

br {
  line-height: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  background: $buttonBgColor; // border-radius: 3px;
  text-align: center;
  color: $bodyFontColor;
}

button:hover {
  background: $buttonHoverColor;
  transition: $transition;
}

button:focus {
  outline: 0 auto -webkit-focus-ring-color;
}

input[type="text"],
input[type="password"] {
  border-radius: 3px;
  -webkit-appearance: none;
}

textarea {
  -webkit-appearance: none;
}

.topblank {
  padding-top: 30px !important;
}

.container {
  position: relative;
  max-width: 1080px;
}

.card {
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(68, 68, 68, 0.2);
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 30px;
}

.util-loading {
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -30px;
  .spinner {
    width: 50px;
    height: 60px;
    text-align: center;
    font-size: 10px;
  }
  .spinner>div {
    background-color: #ffd53f;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 0 2px;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
  }
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  @-webkit-keyframes stretchdelay {
    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.4)
    }
    20% {
      -webkit-transform: scaleY(1.0)
    }
  }
  @keyframes stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
}

.triangle-down {
  width: 0;
  height: 0;
  display: inline-block;
  border-top: 6px solid white;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; // border-bottom: 6px solid transparent;
  margin-left: 5px;
}

.triangle-up {
  width: 0;
  height: 0;
  display: inline-block;
  border-bottom: 6px solid white;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; // border-bottom: 6px solid transparent;
  margin-left: 5px;
}

.triangle-right {
  width: 0;
  height: 0;
  display: inline-block;
  border: 5px solid transparent;
  border-left: 6px solid white;
}

.ie_tip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
  .content {
    position: fixed;
    width: 300px;
    height: 400px;
    margin: 0 auto;
    top: 120px;
    left: 50%;
    margin-left: -150px;
    text-align: center;
    background: url('../imgs/ie_tip.png') no-repeat;
    background-size: 100%;
    font-size: 1.4rem;
    line-height: 26px;
    padding: 0 20px;
    p {
      margin-top: 210px;
      color: #9b9b9b;
      margin-bottom: 10px;
    }
    a {
      color: #4a4a4a;
      display: block;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 767px) {
  .topblank {
    padding-top: 90px !important;
  }
  .topblank_s {
    padding-top: 50px !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  body {
    -webkit-font-smoothing: antialiased;
  }
}

.hide {
  display: none;
}